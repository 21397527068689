import { Injectable } from "@angular/core";
import { Event } from "./event.model";
import { AngularFirestore } from "@angular/fire/firestore";

@Injectable({
  providedIn: "root"
})
export class EventService {
  eventData: Event;

  constructor(private firestore: AngularFirestore) {}

  getEvents() {
    return this.firestore.collection("events").snapshotChanges();
  }
}
