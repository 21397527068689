import { Component, OnInit } from "@angular/core";
import { EventService } from "src/app/shared/event.service";
import * as moment from "moment";
import { AngularFirestore } from "@angular/fire/firestore";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"]
})
export class ListComponent implements OnInit {
  constructor(
    private service: EventService,
    private firestore: AngularFirestore,
    private toastr: ToastrService
  ) {}

  list: unknown[];

  ngOnInit() {
    this.service.getEvents().subscribe(arr => {
      this.list = arr.map(item => {
        return {
          id: item.payload.doc.id,
          ...item.payload.doc.data()
        } as unknown;
      });
    });
  }

  onEdit(item) {
    this.service.eventData = Object.assign({}, item);
    console.log(item);
  }

  onDel(id) {
    if (confirm("Are you sure?")) {
      this.firestore.doc("events/" + id).delete();
      this.toastr.warning("Event Successfully Deleted");
    }
  }
}
