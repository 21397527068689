import { Component, OnInit } from "@angular/core";
import { EventService } from "../shared/event.service";
@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.scss"]
})
export class AdminComponent implements OnInit {
  constructor(private service: EventService) {}

  ngOnInit() {}
}
