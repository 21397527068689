import { Component, OnInit } from "@angular/core";
import { AngularFireModule } from "@angular/fire";
// import * from "@angular/instafeed";

@Component({
  selector: "app-gallery",
  templateUrl: "./gallery.component.html",
  styleUrls: ["./gallery.component.scss"]
})
export class GalleryComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
