import { Component, OnInit } from "@angular/core";
import { EventService } from "../shared/event.service";

@Component({
  selector: "app-event",
  templateUrl: "./event.component.html",
  styleUrls: ["./event.component.scss"]
})
export class EventComponent implements OnInit {
  constructor(private service: EventService) {}

  list: unknown[];

  ngOnInit() {
    this.service.getEvents().subscribe(arr => {
      this.list = arr.map(item => {
        return {
          id: item.payload.doc.id,
          ...item.payload.doc.data()
        } as unknown;
      });
    });
  }
}
