import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { EventComponent } from "./event/event.component";
import { AdminComponent } from "./admin/admin.component";
import { GalleryComponent } from "./gallery/gallery.component";
const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "events", component: EventComponent },
  { path: "gallery", component: GalleryComponent },
  { path: "powerpadmin", component: AdminComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
