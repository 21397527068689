export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyCy-BAuEX3YjoGw0a4Y4u1U0Nut-t_lpfE",
    authDomain: "powerpistonsdb.firebaseapp.com",
    databaseURL: "https://powerpistonsdb.firebaseio.com",
    projectId: "powerpistonsdb",
    storageBucket: "powerpistonsdb.appspot.com",
    messagingSenderId: "196197009095",
    appId: "1:196197009095:web:cae203db1d7de484a820e2",
    measurementId: "G-7QCSVRDCSD"
  }
};
