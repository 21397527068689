import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { EventService } from "src/app/shared/event.service";
import { AngularFirestore } from "@angular/fire/firestore";
import { ToastrModule, ToastrService } from "ngx-toastr";
import * as moment from "moment";

@Component({
  selector: "app-addevent",
  templateUrl: "./addevent.component.html",
  styleUrls: ["./addevent.component.scss"]
})
export class AddeventComponent implements OnInit {
  constructor(
    private service: EventService,
    private firestore: AngularFirestore,
    private toaster: ToastrService
  ) {}

  ngOnInit() {
    this.resetform();
  }
  resetform(form?: NgForm) {
    if (form != null) {
      form.resetForm();
    }
    this.service.eventData = {
      id: null,
      title: "",
      description: "",
      place: "",
      date: "",
      time: ""
    };
  }

  onSubmit(form) {
    var data = Object.assign({}, form.value);
    delete data.id;
    console.log(data);
    if (form.value.id == null) {
      this.firestore.collection("events").add(data);
      this.toaster.success("Added Successfully");
    } else {
      this.firestore.doc("events/" + form.value.id).update(data);
      this.toaster.success("Updated Successfully");
    }
    this.resetform(form);
  }
}
