import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit {
  constructor() {}

  list = [
    {
      author: "Tushar Chavda",
      review:
        "Very good service and support from power piston. One of the best service centre for Royal Enfield in Gandhinagar or Ahmedabad"
    },
    {
      author: "Nikhil Patel",
      review:
        "Very polite and customer friendly behaviour of crew and owner, best place for take care of your royal Enfield...."
    },
    {
      author: "Rahul Modi",
      review:
        "It's the place where you drive your motorcycle for service, repair, accessoires and power parts."
    },
    {
      author: "Yadav Rohit",
      review:
        "One place where you get all expertise related two your dream bike..People are awesome.."
    }
  ];

  ngOnInit() {}
}
