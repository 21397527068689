import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AgmCoreModule } from "@agm/core";
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NavComponent } from "./nav/nav.component";
import { HomeComponent } from "./home/home.component";
import { FooterComponent } from "./footer/footer.component";
import { EventComponent } from "./event/event.component";
import { AdminComponent } from "./admin/admin.component";
import { environment } from "src/environments/environment.prod";
import { AddeventComponent } from "./admin/addevent/addevent.component";
import { ListComponent } from "./admin/list/list.component";
import { EventService } from "./shared/event.service";
import { GalleryComponent } from "./gallery/gallery.component";

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    HomeComponent,
    FooterComponent,
    EventComponent,
    AdminComponent,
    AddeventComponent,
    ListComponent,
    GalleryComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyAcSNfqtSH5J-OIBLaLGvFEJSgYcYjH2uI"
    }),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule
  ],
  providers: [EventService],
  bootstrap: [AppComponent]
})
export class AppModule {}
